@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap");

.shadow-style{
  box-shadow: "0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2)";
  border-radius: "10px";
}

.wrapper {
  margin-left: 0px;
}

.custom_modal {
  top: 50%;
  left: 50%;
  outline: none;
  overflow: auto;
  position: fixed;
  border-radius: 3px;
  transform: translate(-50%, -50%);
  background: #efeeee;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  width: 400px;
}

.custom_overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.78);
}

.page-contents {
  padding: 30px;
}

.btn {
  background: #512986;
  color: white;
  margin: 10px 0 10px 0;
  width: fit-content;
  padding: 10px;
  box-shadow: 1px 1px 5px 0 gray;
  cursor: pointer;
}

.add-btn {
  color: blue;
  cursor: pointer;
  font-size: medium;
}

.add-btn:hover {
  color: purple;
}

.logout {
  margin: 0px;
  float: right;
  padding: 10px;
  background: #efeeee;
  cursor: pointer;
}

.logout:hover {
  box-shadow: 1px 1px 5px 0 gray;
}

.widget-container {
  display: flex;
  flex-wrap: wrap;
}

.dashboard-widget {
  margin: 0 20px 20px 0;
  background: #efeeee;
  width: 250px;
  border-radius: 10px;
  padding: 5px;
  box-shadow: 1px 1px 5px 0 gray;
}

.widget-title {
  text-align: center;
  font-weight: bold;
}

.widget-number-container {
  height: 80px;
  vertical-align: middle;
}

.widget-number {
  font-size: xxx-large;
  text-align: center;
  padding-top: 10px;
}

.list-container a {
  color: black;
  text-decoration: none;
}

.list-header {
  display: flex;
  justify-content: space-between;
  color: white;
  background: #512986;
  padding: 5px;
  margin-bottom: 4px;
}

.list-entry {
  display: flex;
  justify-content: space-between;
  padding: 4px 0 4px 0;
  cursor: pointer;
}

.list-entry:hover {
  background: #efeeee;
}

.list-item {
  flex: 0 0 33%;
}

.form-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.add-form {
  padding: 0 30px 30px 30px;
  border-radius: 5px;
  background: lightgrey;
}

.display-data.container {
  margin-bottom: 20px;
}

.display-location {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.form-section {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  width: 100%;
  border-top: solid grey 1px;
  padding-top: 15px;
  margin-bottom: 10px;
}

.form-section-info {
  flex-grow: 1;
  width: 250px;
}

.form-section-subheading {
  font-size: 20px;
  margin-bottom: 10px;
}

.form-section-description {
  font-size: 14px;
  color: #444;
}

.form-section-content {
  flex-grow: 2;
  width: 250px;
}

.form-control {
  display: inline-block;
  width: 100%;
  margin-bottom: 10px;
}

.form-control select {
  margin-bottom: 20px;
}

.file-field {
  margin-bottom: 30px;
}

.selected-container {
  display: flex;
  flex-wrap: wrap;
}
.selected-item {
  background: lightblue;
  display: flex;
  margin: 3px;
  padding: 2px;
}

.unselected-container .unselected-header {
  display: flex;
  margin-bottom: 0;
  justify-content: space-between;
}

.unselected-header:hover {
  background: lightgray;
}

.unselected-item:hover {
  background: lightgray;
}

.form-control.checkbox {
  display: block;
  position: relative;
  cursor: pointer;
  padding-left: 35px;
  margin-bottom: 20px;
}

.form-control.checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

.form-control.checkbox input:checked ~ .checkmark {
  background-color: #2196f3;
}

.form-control.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.form-control .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  box-sizing: border-box;
  box-shadow: 1px 1px 3px 0 gray inset;
  border-radius: 5px;
}

.checkbox-label {
  padding-top: 3px;
}

.form-child {
  padding: 20px;
}

.remove-button {
  margin-left: 5px;
  border: solid 1px red;
}

.btn-submit {
  opacity: 0.7;
  text-transform: uppercase;
  font-weight: 300;
  border: none;
  font-size: 14px;
  transition-duration: 0.3s;
  width: 100%;
}
.btn-submit:hover {
  opacity: 1;
}

.form-button-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  flex-wrap: wrap;
}

.btn-soc {
  padding: 20px;
  border-radius: 5px;
  cursor: pointer;
}

.btn-soc.cancel {
  border: solid grey 1px;
}

.btn-soc.submit {
  color: white;
  background: #512986;
}

.select-container {
  width: 100%;
}

.select-container .reload {
  cursor: pointer;
  background: LawnGreen;
  display: inline-block;
  width: 25px;
  height: 25px;
}

.select-container select {
  width: calc(100% - 25px);
}

.select-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
}

.select-list-container {
  margin-bottom: 20px;
}

.select-list-item {
  position: relative;
  font-size: small;
  width: 220px;
  min-height: 100px;
  padding: 10px;
  background: white;
  border-radius: 15px;
  border: solid grey 1px;
  cursor: pointer;
}

.select-list-item.selected {
  background: #e6ffe6;
  border: solid green 1px;
}

.select-list-item.empty {
  border: dashed grey 1px;
}

.unused {
  box-shadow: 2px 2px 5px grey inset;
}

.select-list-footer {
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
  gap: 0px 10px;
}

.select-list-heading {
}

.select-list-body {
  display: flex;
  align-items: center;
  gap: 10px;
}

.select-list-checkbox {
  position: absolute;
  top: 5px;
  right: 5px;
  background: green;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  border: solid green 2px;
}

.select-list-image {
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid grey 1px;
  width: 80px;
  height: 80px;
}

.select-list-photo {
  max-width: 100%;
  max-height: 100%;
  display: block;
}

.select-list-hb {
  width: 100%;
  height: 1px;
  margin: 5px;
  background: black;
}

.photo {
  width: 200px;
  max-height: 200px;
}

.photo img {
  width: 100%;
  height: 100%;
}

.flash-message {
  width: 100%;
  border: solid LawnGreen;
}

.add-form input[type="text"] {
}

@media (max-width: 992px) {
  .wrapper {
    padding: 5px;
  }
  .navbar {
    display: none;
  }
  .nav-button {
    display: block;
  }
  .main {
    padding-left: 0;
  }
}

.lds-ellipsis {
  position: relative;
  width: 80px;
  height: 80px;
  margin: auto;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #512986;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
}

.App-link {
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.widget-label-headline{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}